import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { freeCategories, memberCategories, news } from "../components/category";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { loginRequest } from "../authConfig";
import Seo from "../components/seo";
import FreeVideo from "../components/video/freeVideo";

const Markdown = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html, fields } = markdownRemark;
  const category = frontmatter.category;
  const cats = [...memberCategories, ...freeCategories, news];
  const catName = cats.find((c) => c.catSlug === category).catName;
  const { instance } = useMsal();

  return (
    <Layout>
      <Seo title={frontmatter.title} />
      <main>
        <div className="flex flex-col justify-center items-center p-5 md:p-20 bg-cyan-50 text-gray-600 font-sans">
          <h1 className="md:text-2xl text-lg font-medium mb-2 text-center">
            {frontmatter.title}
          </h1>
          <span className="font-number font-light">
            {category === "seminar"
              ? `${new Date(frontmatter.date).getFullYear()}年${new Date(
                  frontmatter.date
                ).getMonth()+1}月`
              : frontmatter.date}
          </span>
        </div>
        <div className="bg-gray-100 text-gray-600">
          <nav className="container mx-auto p-1 md:p-3 font-sans">
            <ol className="list-reset flex items-center text-sm">
              <li>
                <Link to="/" className="text-blue-600 hover:text-blue-700">
                  ホーム
                </Link>
              </li>
              <li className="mx-1">
                <ChevronRightIcon className="w-4 h-4" />
              </li>
              <li>
                <Link
                  to={`/${category}/`}
                  className="text-blue-600 hover:text-blue-700"
                >
                  {catName.length > 11
                    ? catName.substring(0, 10) + "..."
                    : catName}
                </Link>
              </li>
              <li className="mx-1">
                <ChevronRightIcon className="w-4 h-4" />
              </li>
              <li>
                {frontmatter.title.length > 11
                  ? frontmatter.title.substring(0, 10) + "..."
                  : frontmatter.title}
              </li>
            </ol>
          </nav>
        </div>
        <div className="bg-gray-200">
          <div className="container mx-auto max-w-5xl px-5 md:px-10 py-10 md:py-20 text-sm md:text-base bg-white">
            {fields.collection === "member" ? (
              <>
                <AuthenticatedTemplate>
                  <div
                    className="markdown leading-loose"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <div className="flex flex-col justify-center">
                    <div className="text-center mb-3">
                      この記事は会員限定です。
                      <br />
                      会員のお申し込み、コンサルティングのご相談は
                      <Link
                        to="/contact"
                        className="text-cyan-500 hover:text-cyan-400"
                      >
                        お問い合わせ
                      </Link>
                      よりお願いいたします。
                    </div>
                    <div className="text-center mb-3">
                      会員様はログインをお願いいたします。
                    </div>
                    <div className="inline-flex justify-center">
                      <button
                        className="border rounded-sm md:px-4 md:py-2 px-2 py-1 font-medium border-cyan-500 bg-cyan-50 hover:bg-cyan-100"
                        onClick={() => instance.loginPopup(loginRequest)}
                      >
                        会員ログイン
                      </button>
                    </div>
                  </div>
                </UnauthenticatedTemplate>
              </>
            ) : (
              <>
                {category === "seminar" && (
                  <>
                    <FreeVideo
                      videoJson={{
                        title: frontmatter.title,
                        slug: frontmatter.category + "-" + frontmatter.no,
                        series: frontmatter.category,
                      }}
                      withDoc={false}
                    />
                    <div className="w-full text-center font-sans text-sm mt-3">
                      【動画サンプルのご視聴は再生ボタンをクリックしてください】
                    </div>
                  </>
                )}
                <div
                  className="markdown leading-loose"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
                {category !== "seminar" && (
                  <div className="border-t md:pt-10 pt-5 flex justify-center">
                    <Link
                      className="w-full md:w-auto bg-cyan-800 hover:bg-cyan-700 text-white rounded-sm md:px-10 px-5 md:py-5 py-3 md:text-lg text-base block text-center"
                      to="/mag"
                    >
                      無料メールマガジン登録のご案内
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Markdown;

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        slug
        title
        category
        no
      }
      fields {
        collection
      }
    }
  }
`;
